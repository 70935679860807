import * as Types from '../../../graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

/*
* This was automatically generated on by @graphql-codegen.
* DO NOT MANUALLY EDIT !!!
* Please refer to https://github.com/wistia/wistia/blob/master/webpack/graphql/README.md for more information.
* Last Updated: Fri Oct 11 2024 21:45:51 GMT+0000 (Coordinated Universal Time)
*/

const defaultOptions = {} as const;
export type FlagsmithInitialLoadQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FlagsmithInitialLoadQueryQuery = { __typename?: 'Query', currentAccount?: { __typename?: 'Account', id: string, flagsmithV3Enabled: boolean } | null | undefined };


export const FlagsmithInitialLoadQueryDocument = gql`
    query FlagsmithInitialLoadQuery {
  currentAccount {
    id
    flagsmithV3Enabled
  }
}
    `;

/**
 * __useFlagsmithInitialLoadQueryQuery__
 *
 * To run a query within a React component, call `useFlagsmithInitialLoadQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlagsmithInitialLoadQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlagsmithInitialLoadQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFlagsmithInitialLoadQueryQuery(baseOptions?: Apollo.QueryHookOptions<FlagsmithInitialLoadQueryQuery, FlagsmithInitialLoadQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FlagsmithInitialLoadQueryQuery, FlagsmithInitialLoadQueryQueryVariables>(FlagsmithInitialLoadQueryDocument, options);
      }
export function useFlagsmithInitialLoadQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FlagsmithInitialLoadQueryQuery, FlagsmithInitialLoadQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FlagsmithInitialLoadQueryQuery, FlagsmithInitialLoadQueryQueryVariables>(FlagsmithInitialLoadQueryDocument, options);
        }
export function useFlagsmithInitialLoadQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FlagsmithInitialLoadQueryQuery, FlagsmithInitialLoadQueryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FlagsmithInitialLoadQueryQuery, FlagsmithInitialLoadQueryQueryVariables>(FlagsmithInitialLoadQueryDocument, options);
        }
export type FlagsmithInitialLoadQueryQueryHookResult = ReturnType<typeof useFlagsmithInitialLoadQueryQuery>;
export type FlagsmithInitialLoadQueryLazyQueryHookResult = ReturnType<typeof useFlagsmithInitialLoadQueryLazyQuery>;
export type FlagsmithInitialLoadQuerySuspenseQueryHookResult = ReturnType<typeof useFlagsmithInitialLoadQuerySuspenseQuery>;
export type FlagsmithInitialLoadQueryQueryResult = Apollo.QueryResult<FlagsmithInitialLoadQueryQuery, FlagsmithInitialLoadQueryQueryVariables>;